<template>
  <div id="grading-scales" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="gradingScales" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          gradingScales.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Low Mark" v-slot="props">{{
          props.row.lowMark
        }}</b-table-column>

        <b-table-column label="High Mark" v-slot="props">{{
          props.row.highMark
        }}</b-table-column>

        <b-table-column label="Remark" v-slot="props">{{
          props.row.remark
        }}</b-table-column>

        <b-table-column label="Grade" v-slot="props">{{
          props.row.grade
        }}</b-table-column>

        <b-table-column label="Category" v-slot="props">{{
          props.row.category
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-grading-scale"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'GradingScales',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Grading Scales',
      gradingScales: [],
    }
  },
  methods: {
    initializeInfo(grading_scale) {
      // this.$router.push(
      //   `/school/${this.schoolId}/grading_scale_info/${grading_scale.id}`
      // )
    },
    initializeUpdate(grading_scale) {
      this.$router.push(
        `/school/${this.schoolId}/grading_scale/${grading_scale.id}`
      )
    },
    initializeRemove(grading_scale) {
      this.id = parseInt(grading_scale.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteGradingScaleQuery($id: Int!) {
              deleteGradingScale(input: { id: $id }) {
                gradingScale {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteGradingScale.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.gradingScales.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.schoolId}/grading_scale/new`,
      },
    ])

    this.$apollo.addSmartQuery('gradingScales', {
      query: gql`
        query GradingScalesQuery($schoolId: Int!) {
          gradingScales(schoolId: $schoolId) {
            id
            lowMark
            highMark
            remark
            grade
            category
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
    })
    this.$apollo.queries.gradingScales.refetch()
  },
}
</script>
